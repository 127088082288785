<template>
  <div class="pin-popup">
    <div class="pin-popup-content">
      <div class="text">
        <div class="descr">{{ $t("Pin.title") }}</div>
        <div class="pin">{{ roomNumber }}</div>
      </div>
    </div>

    <div class="pin-overlay"></div>
  </div>
</template>

<script>
export default {
  name: "Pin",
  props: {
    roomNumber: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="less">
.pin-popup-overlay.fade-enter,
.pin-popup-overlay.fade-leave-to {
  opacity: 0.5;
}

.pin-popup {
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 999;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.pin-popup-content {
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 999;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.pin-popup .text {
  height: 225px;
  position: absolute;
  top: 50%;
  margin-top: -142.5px;
  left: 15px;
  right: 15px;
  text-align: center;
  font-size: 17px;
  color: #fff;
}

.pin-popup .descr {
  line-height: 1.25;
  font-size: 51px;
}

.pin-popup .pin {
  font-size: 150px;
  margin-top: 31px;
}

.pin-popup .close.button {
  position: absolute;
  bottom: 15px;
  width: 276px;
  text-align: center;
  left: 50%;
  margin-left: -145px;
  border: 1px solid #414141;
}

.pin-popup .close.button:hover {
  color: #7a7a7a;
}
</style>
