<template>
  <div v-if="this.seconds" ref="time" class="timer icon-hourglass">
    <div :class="{ [`timer__time--${theme}`]: theme }" class="timer__time">
      {{ time }}
    </div>
  </div>
</template>

<script>
import EasyTimer from "easytimer";
import { mapActions, mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";
import { GET_ROOM_TIMER } from "@/store/modules/common/action-types";

const ZERO_TIME = `00:00:00`;

export default {
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      time: null,
      timer: null,
      serverTimer: null,
    };
  },
  computed: {
    ...mapState(room, {
      seconds: state => state.timer.seconds || 0,
    }),
  },
  watch: {
    seconds() {
      this.startTimer();
    },
  },
  created() {
    this.timer = new EasyTimer();
    this.timer.addEventListener("secondsUpdated", () => {
      const value = this.timer.getTimeValues().toString();
      this.time = value !== ZERO_TIME ? value : null;
    });
  },
  mounted() {
    this.getRoomTimer().then(() => {
      this.startTimer();
    });

    this.serverTimer = setInterval(() => {
      this.getRoomTimer();
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.serverTimer);
  },
  methods: {
    ...mapActions(room, {
      getRoomTimer: GET_ROOM_TIMER,
    }),
    startTimer() {
      if (this.seconds === 0) {
        this.timer.stop();
        return;
      }
      this.timer.stop();
      this.timer.start({
        countdown: true,
        precision: "seconds",
        startValues: { seconds: this.seconds },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  &:before {
    font-size: 18px;
    color: var(--base-color);
  }
}

.timer__time {
  font-family: "ProximaNova", sans-serif;
  font-size: 14px;
  color: #0e0e0e;
  text-align: left;
  box-sizing: content-box;
  padding-left: 5px;
  margin-right: 10px;

  &--monitor {
    font-size: 27px;
    color: #000;
  }
}
</style>
