import { render, staticRenderFns } from "./CreateGroupChat.vue?vue&type=template&id=02581a42&scoped=true&"
import script from "./CreateGroupChat.vue?vue&type=script&lang=js&"
export * from "./CreateGroupChat.vue?vue&type=script&lang=js&"
import style0 from "./CreateGroupChat.vue?vue&type=style&index=0&id=02581a42&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02581a42",
  null
  
)

export default component.exports