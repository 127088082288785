<template>
  <apollo-query
    :query="queries.userInfo"
    fetchPolicy="network-only"
    :debounce="1"
    class="query"
    @error="creteNewUser"
    @result="updateUserId"
  >
    <template v-slot="{}">
      <apollo-query
        v-if="isLoadChats"
        ref="chatsQuery"
        :query="queries.chats"
        fetchPolicy="network-only"
        :debounce="500"
        :variables="{
          first: 300,
          after: 0,
        }"
        class="chat-list"
        @result="chatResultHandler"
      >
        <template v-slot="{ result: { error }, isLoading }">
          <loader v-if="isLoading" class="apollo-query__loading" />
          <div v-else-if="error" class="error apollo">
            {{ $t("Chat.errorChatList") }}
          </div>
          <div v-else-if="channels.length" class="container-ws-item-list">
            <ws-item-list
              v-for="item in channels"
              :key="`chats-${item.id}`"
              :ref="`refChat-${item.id}`"
              :title="item.title"
              :count="unreadMessagesCount(item)"
              :image="item.avatar"
              :imageSize="32"
              :item="item"
              @mounted="loadChats"
              @click="
                createChat(item.id, item.messages && item.messages.additionalInfo, item.avatar)
              "
            >
              <template #default>
                <apollo-subscribe-to-more
                  :document="subscribes.messages"
                  :variables="{ channel_id: item.id }"
                  :updateQuery="commonUpdateQueryHandler"
                />
                <apollo-subscribe-to-more
                  :document="subscribes.update"
                  :variables="{ channel_id: item.id }"
                  :updateQuery="commonUpdateQueryHandler"
                />
              </template>
              <template #icon>
                <ws-icon xxl light color="primary">chevron-right</ws-icon>
              </template>
            </ws-item-list>
          </div>
          <footer-chat v-if="!isGuest && !privateChatsDisabled">
            <ws-button lg color="primary" @click="createNewChat">
              <ws-icon xl light>plus</ws-icon>
              {{ $t("Chat.createNewChat") }}
            </ws-button>
            <apollo-subscribe-to-more
              :document="subscribes.invites"
              :variables="{ user_id: userId }"
              :updateQuery="commonUpdateQueryHandler"
            />
          </footer-chat>
        </template>
      </apollo-query>
    </template>
  </apollo-query>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import Loader from "@/components/common/elements/Loader";
import { MY_CHANNELS, GET_PROFILE } from "@/components/chat/GraphQl/query/query";
import {
  CHAT_JOINED_USER_TO_CHANNEL,
  CHAT_ADDED_MESSAGE_TO_CHANNEL,
  CHAT_UPDATE_CHANNEL,
} from "@/components/chat/GraphQl/subscriptions/subscriptions";
import {
  CHAT_CREATE_NEW_USER,
  ADD_USER_TO_CHANNEL,
} from "@/components/chat/GraphQl/mutation/mutation";
import { chats, chat, auth, room } from "@/store/modules/store.namespaces";
import { CHATS_SET_PROPS } from "@/store/modules/chats/mutation-types";
import { SET_UNDREAD_MESSAGES } from "@/store/modules/chat/mutation-types";
import { channelTypes } from "@/constants/chats/chats-consts";
import { convertImageUri } from "@/components/chat/ChatUtils.js";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { loadUsersAvatars } from "@/services/FileUploadService";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import { updateChatId } from "../ChatService";
import FooterChat from "../components/Footer";
import WsItemList from "../components/ItemList";

export default {
  name: "ChatList",
  components: {
    WsItemList,
    WsButton,
    FooterChat,
    WsIcon,
    Loader,
  },
  data() {
    return {
      channels: [],
      userId: "",
      channelsQuery: null,
      isLoadChats: false,
      isMutateOnce: true,
    };
  },
  computed: {
    ...mapState(room, {
      commonChatId: state => state.roomInfo?.external_chat_id,
      entityId: state => state.roomInfo?.external_chat_entity_id,
    }),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapState(auth, {
      userName: state => state.user && state.user.fio,
      isGuest: state => !!state.user.is_guest,
      groupChatId: state => +state.user.groupChatId,
      userAvatar: state => state.user.photoWithUrl,
    }),
    isTeamChatAvailable() {
      return this.availableRoomModules.teamChatMode;
    },
    normalizedUserName() {
      return this.userName.trim() || this.$t("Chat.guest");
    },
    queries() {
      return {
        chats: MY_CHANNELS,
        userInfo: GET_PROFILE,
      };
    },
    subscribes() {
      return {
        messages: CHAT_ADDED_MESSAGE_TO_CHANNEL,
        invites: CHAT_JOINED_USER_TO_CHANNEL,
        update: CHAT_UPDATE_CHANNEL,
      };
    },
    mutations() {
      return {
        newUser: CHAT_CREATE_NEW_USER,
        joinUser: ADD_USER_TO_CHANNEL,
      };
    },
    privateChatsDisabled() {
      return this.availableRoomModules.disablePrivateChat;
    },
  },
  watch: {
    commonChatId(id, oldId) {
      if (id && id !== oldId) {
        this.isLoadChats = false;
        this.addUserChannel();
      }
    },
  },
  mounted() {
    this.creteNewUser();
  },
  methods: {
    ...mapMutations(chat, {
      setUnreadMessageCount: SET_UNDREAD_MESSAGES,
    }),
    ...mapMutations(chats, {
      updateChatsProps: CHATS_SET_PROPS,
    }),
    imageUri({ avatar }) {
      return convertImageUri(avatar);
    },
    isPrivateChannel(item) {
      return channelTypes[item.type] === "private";
    },
    isPublicChannel(item) {
      return channelTypes[item.type] === "public";
    },
    commonUpdateQueryHandler() {
      if (this.channelsQuery) this.channelsQuery.refetch();
    },
    unreadMessagesCount(item) {
      if (item.messages) {
        const messageCount = item.messages.additionalInfo.unreadCount;
        return messageCount > 0 ? messageCount : 0;
      }
      return 0;
    },
    unreadMessages(channels) {
      return channels.reduce((sum, item) => {
        return sum + this.unreadMessagesCount(item);
      }, 0);
    },
    sortChannels(chanOne, chanTwo) {
      if (this.isPublicChannel(chanOne)) return -1;
      if (this.isPublicChannel(chanTwo)) return 1;
      return 0;
    },
    getChannels({ data, query }) {
      if (query) this.channelsQuery = query;
      const channels = this.filterChannels(data.myChannels);
      this.setAvatars(channels);
      this.setUnreadMessageCount(this.unreadMessages(channels));
      return channels
        .map(item => {
          item.title = this.getTitle(item);
          return item;
        })
        .filter(item => {
          if (this.privateChatsDisabled) {
            return item.type === channelTypes.public;
          }
          return true;
        });
    },
    filterChannels(channels = []) {
      return channels
        .filter(
          channel =>
            channel.entityId === this.entityId ||
            !channel.entityId ||
            channel.type === channelTypes.public,
        )
        .filter(channel => (this.isTeamChatAvailable ? true : channel.id !== this.groupChatId))
        .filter(
          channel =>
            channel.type === channelTypes.private ||
            channel.type === channelTypes.group ||
            (channel.type === channelTypes.public && channel.id === this.commonChatId) ||
            (channel.type === channelTypes.public && channel.id === this.groupChatId),
        )
        .sort(this.sortChannels);
    },
    setAvatars(channels) {
      channels.forEach(async channel => {
        if (channel.type === channelTypes.group && channel.avatar) {
          channel.avatar = convertImageUri(channel.avatar);
        } else if (channel.type === channelTypes.private && !channel.avatar) {
          const { id } = channel.userConnections.edges[0];
          const { data } = await loadUsersAvatars([id]);
          channel.avatar = data[id];
        }
      });
    },
    getTitle({ name, type, id }) {
      if (channelTypes[type] === "public") {
        if (id === this.commonChatId) return this.$t("Chat.commonTitle");
        if (id === this.groupChatId) return this.$t("Chat.groupTitle");
      }
      return name;
    },
    createChat(channelId, { lastId, unreadCount }, avatar) {
      /**
       * !this can also be
       * backend can send unreadCount < 0
       * and lastId not checked on backend
       */
      const count = unreadCount > 0 ? unreadCount : -unreadCount;
      const id = unreadCount > 0 ? lastId : lastId - count - 1;
      this.updateChatsProps({
        channelId,
        lastMsgId: id || 0,
        countMsgs: count || 10,
        userId: this.userId,
        avatar,
      });
      this.$emit("change-view", "Messages", { unreadCount });
    },
    createNewChat() {
      this.$emit("change-view", "CreateChat");
    },
    updateUserId(data) {
      if (data.data.profile) {
        this.userId = data.data.profile.user.id;
        this.addUserChannel();
        this.updateChatsProps({
          userId: this.userId,
        });
      }
    },
    creteNewUser() {
      if (this.isMutateOnce) {
        this.$apollo
          .mutate({
            mutation: CHAT_CREATE_NEW_USER,
            throttle: 1000,
            variables: {
              name: this.normalizedUserName,
              is_guest: this.isGuest,
            },
          })
          .then(data => {
            this.userId = data.data.chatCreateNewUser.id;
            this.addUserChannel();
            updateChatId(this.userId);
          })
          .catch(error => {
            throw new Error(error);
          });
        this.isMutateOnce = false;
      }
    },
    addUserChannel() {
      if (this.commonChatId && this.userId)
        this.$apollo
          .mutate({
            mutation: ADD_USER_TO_CHANNEL,
            fetchPolicy: "no-cache",
            variables: {
              user_id: this.userId,
              channel_id: this.commonChatId,
            },
          })
          .then(data => {
            if (data?.data?.addUserToChannel?.id) {
              this.isLoadChats = true;
            }
          })
          .catch(error => {
            throw new Error(error);
          });
    },
    loadChats(item) {
      if (this.isGuest && this.commonChatId === item.id) {
        const refChat = `refChat-${this.commonChatId}`;
        this.$refs[refChat][0].$emit("click", item);
      }
    },
    chatResultHandler(event) {
      this.channels = this.getChannels({
        data: event.data,
        query: this.$refs.chatsQuery.$apollo.queries.query,
      });
    },
  },
};
</script>

<style lang="less">
@import "~@/styles/_vars";

@base: #f28b00;
.query {
  height: 100%;
}

.chat-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow: hidden;
}

.container-ws-item-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow: auto;
  cursor: pointer;
  -webkit-overflow-scrolling: touch;

  .ws-item-list__title {
    overflow-x: hidden;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.error.apollo {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
  color: @error;
  text-align: center;
  transform: translate(-50%, -50%);
}
</style>
