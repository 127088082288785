<template>
  <div
    :class="{
      [$style.pin]: pinStatus,
    }"
    class="flex flex-column"
  >
    <div :class="$style.header">
      <timer theme="monitor" />
      <pin-message v-if="pinStatus" :room-number="roomNumber" />
    </div>
    <ws-button lg icon :class="$style.menuBtn" color="primary" @click="toggleUserPopup">
      <ws-icon xxl color="default">ellipsis-v</ws-icon>
    </ws-button>
    <div :class="$style.content" class="flex flex-column">
      <router-view />
    </div>
    <interactive v-if="interactiveType" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { polls, room } from "@/store/modules/store.namespaces";
import { interactive } from "@/constants/polls/polls-module-types";
import { TOGGLE_USER_POPUP_VISIBLE } from "@/store/modules/common/mutation-types";
import PinMessage from "@/components/screen/room/PinMessage";
import Timer from "@/components/common/timer/Timer";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import Interactive from "../../components/screen/Interactive/Interactive";

export default {
  components: {
    Interactive,
    PinMessage,
    Timer,
    WsButton,
    WsIcon,
  },
  computed: {
    ...mapState(room, ["roomNumber"]),
    ...mapState(["pinStatus"]),
    ...mapState(`${polls}/${interactive}`, ["interactiveType"]),
  },
  methods: {
    ...mapMutations(room, {
      toggleUserPopup: TOGGLE_USER_POPUP_VISIBLE,
    }),
  },
};
</script>

<style lang="scss" module>
.content {
  flex: 1 0 auto;
  overflow: auto;
}

.menuBtn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
}

.header {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 10;
}

.pin {
  padding-top: 60px;

  .header {
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px;
    background-color: #f8f8f8;
    opacity: 1;
  }

  .menuBtn {
    height: 60px;
    border-radius: 0;
  }
}
</style>
