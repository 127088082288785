<template>
  <div class="footer-chat">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FooterChat",
};
</script>

<style scoped>
.footer-chat {
  position: absolute;
  bottom: 0;
  background: #fff;
  z-index: 9;
  padding: 10px 11px 14px 11px;
  width: 100%;
  border-top: 1px solid #dcdcdc;
}
</style>
