<template>
  <div class="container-scroll">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContainerScroll",
};
</script>

<style scoped>
.container-scroll {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
