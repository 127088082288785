<template>
  <transition :duration="{ enter: 300, leave: 300 }" name="fade">
    <div v-show="visible" class="nav-popup__container">
      <div class="nav-popup__overlay" @click="toggleUserPopupVisible"></div>
      <div class="nav-popup">
        <div class="nav-popup__header">
          <landing-lang-selector class="header__language" />
          <i class="header__close icon-close" @click="toggleUserPopupVisible"></i>
        </div>
        <div class="nav-popup__content">
          <screen-navigation
            :nav-items="navItems"
            :polls-select-available="!!availableRoomModules.cheat_points"
            :replace-room-available="availableRooms"
            @pin-status="setPinStatus"
            @toggle-sidebar="toggleUserPopupVisible"
          />
        </div>
        <div class="nav-popup__logout">
          <button class="logout__button icon-logout" @click="logout">
            {{ $t("userPopup.logout") }}
          </button>
          <span class="logout__version">{{ $t("common.version") }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import LandingLangSelector from "@/components/common/landing/LandingLangSelector";
import ScreenNavigation from "@/components/screen/navigation/ScreenNavigation";
import { SET_PIN_STATUS } from "@/store/mutations/mutation-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { auth, room } from "@/store/modules/store.namespaces";
import { LOGOUT } from "@/store/modules/auth/action-types";
import { TOGGLE_USER_POPUP_VISIBLE } from "@/store/modules/common/mutation-types";
import { CHANGE_ROOM } from "@/store/modules/common/action-types";

export default {
  name: "NavPopup",
  components: {
    LandingLangSelector,
    ScreenNavigation,
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapState(room, {
      visible: state => state.userPopupVisible,
    }),
    ...mapState(auth, {
      availableRooms: state => !!state.user.room.length,
    }),
    navItems() {
      return [
        this.navItemFactory(
          "presentation",
          this.availableRoomModules.details,
          this.$t(`navigation.presentations`),
        ),
        this.navItemFactory("brainstorm"),
        this.navItemFactory("photowall"),
        this.navItemFactory("questions"),
        this.navItemFactory("messenger", this.availableRoomModules.groupchat),
      ].filter(item => item.visible);
    },
  },
  watch: {
    $route(route) {
      localStorage.setItem("routerName", route.name);
    },
  },
  methods: {
    ...mapMutations({
      setPinStatus: SET_PIN_STATUS,
    }),
    ...mapActions(auth, {
      logout: LOGOUT,
    }),
    ...mapMutations(room, {
      toggleUserPopupVisible: TOGGLE_USER_POPUP_VISIBLE,
    }),
    ...mapActions(room, {
      changeRoom: CHANGE_ROOM,
    }),
    navItemFactory(
      name,
      visible = this.availableRoomModules[name],
      text = this.$t(`navigation.${name}`),
      to = name,
    ) {
      return { name, visible, text, to };
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/_mixin";

.nav-popup {
  position: absolute;
  top: 21px;
  bottom: 32px;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 19px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: -20px 0 60px 0 rgba(0, 0, 0, 0.29);
  transform: translateX(-50%);

  &__overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: var(--base-fade-color);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
  }

  &__container {
    z-index: 110;
  }

  &__content {
    overflow: auto;

    .reset-scroll();
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__logout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
    padding-top: 10px;
  }

  &__logout,
  &__header {
    flex: 1 0 auto;
  }
}

.header {
  &__language {
    margin: 0;
  }

  &__close {
    width: 34px;
    height: 34px;
    font-size: 34px;
    color: #000000;
    opacity: 0.5;
  }
}

.logout {
  &__button {
    position: relative;
    width: 246px;
    height: 39px;
    margin: 0 auto;
    font-family: ProximaNova;
    font-size: 18px;
    color: #353535;
    text-align: center;
    cursor: pointer;
    background: #fff;
    border: solid 1px #d3d3d3;
    border-radius: 8px;
    transition: all ease 0.3s;

    &::before {
      font-family: simple-line-icons;
      position: absolute;
      right: 27px;
      top: 10px;
      color: #d3d3d3;
      font-size: 19px;
      line-height: 1;
      transition: all ease 0.3s;
    }
    &:hover {
      border-color: var(--base-color-lighter);
      color: var(--base-color-lighter);
      &::before {
        color: var(--base-color-lighter);
      }
    }
  }

  &__version {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #979797;
  }
}

.nav-popup__container.fade-enter,
.nav-popup__container.fade-leave-to {
  opacity: 0;
}
</style>
