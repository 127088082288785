<template>
  <div class="chat-container">
    <component
      :is="currentView"
      v-bind="componentProps"
      @change-view="changeView"
      @go-back-view="goBackView"
      @go-home-view="goHomeView"
    />
  </div>
</template>

<script>
import ChatList from "./views/ChatList";
import CreateChat from "./views/CreateChat";
import CreateGroupChat from "./views/CreateGroupChat";
import Messages from "./views/Messages";

export default {
  name: "Chat",
  components: {
    ChatList,
    CreateChat,
    CreateGroupChat,
    Messages,
  },
  data() {
    return {
      componentStack: ["ChatList"],
      componentProps: {},
    };
  },
  computed: {
    currentView() {
      if (this.componentStack.length) return this.componentStack[this.componentStack.length - 1];
      return "ChatList";
    },
  },
  methods: {
    changeView(component, props = {}) {
      if (
        !this.componentStack.length ||
        this.componentStack[this.componentStack.length - 1] !== component
      ) {
        this.componentProps = props;
        this.componentStack.push(component);
      }
    },
    goBackView(step) {
      this.componentStack = this.componentStack.slice(0, step);
    },
    goHomeView() {
      this.componentStack = ["ChatList"];
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  height: 100%;
  background-color: $white;

  &__router {
    height: 100%;
  }
}
</style>
