<template>
  <div
    class="interactive"
    :class="[
      interactiveTheme.class,
      {
        'interactive--pin': pinStatus,
      },
    ]"
    :style="interactiveTheme.styles"
  >
    <component :is="componentType" :is-screen="true" v-bind="componentProps"></component>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Game from "@/components/event/polls/Game";
import PollScores from "@/components/common/polls/components/PollScores";
import Poll from "@/components/common/polls/components/Poll";
import PollRunning from "@/components/common/polls/components/PollRunning";
import PollTest from "@/components/common/polls/components/PollTest";
import { polls, pollsInteractive } from "@/store/modules/store.namespaces";
import { interactive } from "@/constants/polls/polls-module-types";
import { POLL, POLL_TEST, POLL_RUNNING } from "@/constants/polls/poll-interactive-types";
import { INTERACTIVE_THEME } from "@/store/modules/polls/getter-types";

export default {
  components: {
    Poll,
    PollRunning,
    PollScores,
    PollTest,
    Game,
  },
  computed: {
    ...mapState(pollsInteractive, [
      "chain",
      "activePoll",
      "sendingStatus",
      "interactiveType",
      "chainScore",
    ]),
    ...mapGetters(polls, {
      interactiveTheme: INTERACTIVE_THEME,
    }),
    ...mapState(["pinStatus"]),
    componentType() {
      if (this.interactiveType === POLL && this.activePoll.is_voting) {
        return POLL_RUNNING;
      }
      return this.interactiveType;
    },
    componentProps() {
      if (this.interactiveType === POLL || this.interactiveType === POLL_TEST) {
        return {
          sendingStatus: this.sendingStatus,
          pollChain: this.chain,
          poll: this.activePoll,
          moduleType: interactive,
        };
      }
      return {
        pollChain: this.chainScore,
        moduleType: interactive,
      };
    },
  },
  created() {
    if (!this.interactiveType) {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="less">
.interactive {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: white;

  @media (max-width: 650px) {
    padding: 5%;
  }

  &--header {
    min-height: 50px;
  }

  &--content {
    flex: auto;
  }

  &.interactive--bg-dark {
    background-image: linear-gradient(154deg, #4b4b4b, #282828);
  }

  &.interactive--bg-custom {
    background: #fff no-repeat center;
    background-size: cover;
  }

  ::v-deep {
    .poll-scores {
      height: 100%;
    }

    .cloud {
      height: ~"calc(100vh - 145px)";
    }
  }
}

.interactive--pin {
  top: 60px;

  ::v-deep {
    .word-cloud {
      min-height: 70%;
    }

    .cloud {
      height: ~"calc(100vh - 204px)";
    }

    .votes-count + .cloud {
      height: ~"calc(100vh - 263px)";
    }
  }
}
</style>
