<template>
  <div>
    <section v-if="rooms.length" class="user-rooms">
      <div class="user-rooms__title">
        {{ title || $t("common.division") }}
      </div>
      <ul class="user-rooms__list">
        <li
          v-for="room in rooms"
          :id="room.number"
          :key="room.number"
          class="user-rooms__item"
          :class="{ 'user-rooms__item_active': isCurrentRoom(room.number) }"
          @click="changeRoom(room.number)"
        >
          <img
            v-if="room.logo.length"
            class="user-rooms__image"
            :src="imagePath(room.logo)"
            :alt="`# ${room.number}`"
          />
          <span v-else class="user-rooms__alt"> #{{ room.number }} </span>
        </li>
      </ul>
    </section>
    <section v-else class="user-rooms">
      <div class="user-rooms__title">
        {{ $t("common.division") }}
      </div>
      <ul class="user-rooms__list">
        <li class="user-rooms__item user-rooms__item_active">
          <span class="user-rooms__alt"> #{{ currentRoom }} </span>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { auth, room } from "@/store/modules/store.namespaces";
import { CHANGE_ROOM } from "@/store/modules/common/action-types";
import { makeUrl } from "@/utils";

export default {
  name: "ReplaceRoom",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(auth, {
      rooms: state => state.user.room.filter(room => room.number),
    }),
    ...mapState(room, {
      currentRoom: "roomNumber",
    }),
  },
  methods: {
    ...mapActions(room, {
      changeRoomAction: CHANGE_ROOM,
    }),
    isCurrentRoom(roomNum) {
      return this.currentRoom === roomNum;
    },
    changeRoom(roomNum) {
      if (this.isCurrentRoom(roomNum)) return;
      this.$emit("room-change", roomNum);
      this.changeRoomAction(roomNum);
      localStorage.removeItem("agentIdFilter");
      localStorage.removeItem("agentId");
    },
    imagePath(src) {
      return makeUrl(src);
    },
  },
};
</script>

<style scoped lang="less">
.user-rooms {
  margin-top: 28px;

  &__title {
    margin-bottom: 6px;
    font-family: ProximaNova;
    font-size: 16px;
    text-align: center;
    color: #818181;
  }

  &__list {
    padding: 0;
    margin: 0 0 7px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__item {
    position: relative;
    width: 84px;
    height: 84px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #e2e2e2;
    filter: grayscale(60%);

    &:hover {
      border: 1px solid var(--base-color);
      filter: none;
    }

    &:nth-child(3n) {
      margin: 0;
    }
  }

  &__item_active {
    position: relative;
    border: 1px solid var(--base-color);
    filter: none;

    &::after {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 14px;
      height: 14px;
      content: "";
      background-color: var(--base-color);
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23ffffff' stroke-width='1.1' points='4,10 8,15 17,4'%3E%3C/polyline%3E%3C/svg%3E");
      background-size: cover;
      fill: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 50%;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__alt {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-family: ProximaNova;
    font-size: 16px;
    user-select: none;
  }
}
</style>
