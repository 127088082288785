<template>
  <layout-page>
    <screen-container :class="$style.content" />
    <!--
        initialize chats
        load chats list, subscribe on new messages
        and update new messages count in badget
      -->
    <chat style="display: none" />
    <screen-web-socket-container />
    <nav-popup />
    <pin v-if="pinBarVisible" :room-number="roomNumber" />
  </layout-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Chat from "@/components/chat/Chat";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import {
  auth,
  questions,
  room,
  presentation,
  notifications,
} from "@/store/modules/store.namespaces";
import { GET_USER_QUESTIONS } from "@/store/modules/questions/action-types";
import { GET_PRESENTATIONS, CHANGE_SLIDE_BY_KEY } from "@/store/modules/presentation/action-types";
import { GET_MOSCOW_TIME, GET_ROOM_TIMER } from "@/store/modules/common/action-types";
import {
  GET_LAST_NOTIFICATION,
  GET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/action-types";
import { ROLE_SET_MODE } from "@/store/mutations/mutation-types";
import LayoutPage from "@/components/layouts/LayoutPage";
import ScreenWebSocketContainer from "../../components/screen/ScreenWebSocketContainer.vue";
import Pin from "../../components/event/Pin.vue";
import NavPopup from "../../components/screen/navigation/NavPopup.vue";
import ScreenContainer from "../../components/screen/ScreenContainer.vue";

export default {
  name: "ViewScreen",
  components: {
    ScreenContainer,
    NavPopup,
    Pin,
    ScreenWebSocketContainer,
    Chat,
    LayoutPage,
  },
  data() {
    return {
      shownNotification: null,
      needRestoreState: false,
    };
  },
  computed: {
    ...mapState(room, ["roomNumber", "roomPending"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapState(auth, ["user"]),
    ...mapState({
      pinBarVisible: state => state.pinbar.visible,
    }),
  },
  watch: {
    roomPending(val) {
      if (!val) {
        this.setRightRoute();
      }
    },
  },
  created() {
    this.setRightRoute();
    this.setRoleMode("screen");
    this.addRestoreStateListeners();
    document.addEventListener("keyup", this.changeSlideByKey);
  },
  beforeDestroy() {
    this.removeRestoreStateListeners();
    document.removeEventListener("keyup", this.changeSlideByKey);
  },
  methods: {
    ...mapActions(questions, {
      getUserQuestions: GET_USER_QUESTIONS,
    }),
    ...mapActions(presentation, {
      getPresentations: GET_PRESENTATIONS,
      changeSlideByKey: CHANGE_SLIDE_BY_KEY,
    }),
    ...mapActions(room, {
      getRoomTimer: GET_ROOM_TIMER,
      getMoscowTime: GET_MOSCOW_TIME,
    }),
    ...mapActions(notifications, {
      getUnreadNotifs: GET_UNREAD_NOTIFICATIONS,
      getLastNotifications: GET_LAST_NOTIFICATION,
    }),
    ...mapMutations({
      setRoleMode: ROLE_SET_MODE,
    }),
    setRightRoute() {
      const navModules = [
        { name: "details", routeName: "scpresentation" },
        { name: "brainstorm", routeName: "screenBrainstorm" },
        { name: "photowall", routeName: "scphotowall" },
        { name: "questions", routeName: "scquestions" },
        { name: "groupchat", routeName: "scmessenger" },
      ];
      const storageRoute = localStorage.getItem("routerName");
      const storageModule = navModules.find(nav => nav.routeName === storageRoute)?.name;
      const routeName = this.availableRoomModules[storageModule]
        ? storageRoute
        : navModules.find(nav => this.availableRoomModules[nav.name])?.routeName;

      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName, params: { id: this.roomNumber } });
      }
    },
    addRestoreStateListeners() {
      window.addEventListener("blur", this.setNeedRestoreState);
      window.addEventListener("focus", this.restoreState);
    },
    setNeedRestoreState() {
      this.needRestoreState = true;
    },
    restoreState() {
      if (this.needRestoreState) {
        this.getRoomTimer();
        this.getMoscowTime();
        this.getUnreadNotifs();
        this.getUserQuestions();
        this.getPresentations();
        this.needRestoreState = false;
      }
    },
    removeRestoreStateListeners() {
      window.removeEventListener("blur", this.setNeedRestoreState);
      window.removeEventListener("focus", this.restoreState);
    },
  },
};
</script>
<style lang="scss" module>
.content {
  grid-area: content;
  overflow: auto;
}
</style>
