<template>
  <div class="layout-indent-sides">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LayoutIndentSides",
};
</script>

<style scoped>
.layout-indent-sides {
  margin: 0 10px;
}
</style>
