<template>
  <div class="quiz-wrapper">
    <div v-if="!showPostScreen" class="quiz">
      <component
        :is="activePollType"
        ref="pollComponent"
        :class="gameAlias"
        class="question-block"
        :poll="activePoll"
        :is-mountain-game="true"
        module-type="test"
      >
        <div slot="timer" class="timer-wrapper">
          <transition name="timer-fade">
            <test-timer
              :initial-time-in-seconds="timeForGame"
              class="timer"
              @timer-tick="timeTicker($event)"
              @time-is-out="timeIsOut()"
            ></test-timer>
          </transition>
        </div>
      </component>
      <br />
      <div class="control-buttons">
        <poll-control-button
          v-if="skipBtnAvailable"
          class="skip"
          @click="skipQuestion()"
          >{{ $t("testNGames.skip") }}</poll-control-button
        >
        <poll-control-button @click="postQuizAnswer">{{
          $t("testNGames.answer")
        }}</poll-control-button>
      </div>
    </div>
    <slot v-if="showPostScreen" name="postScreen"></slot>
    <test-progress-bar
      v-if="!showPostScreen"
      :questions-count="questionsCount"
      :current-question-number="currentQuestion"
      legend-to-bottom="true"
    ></test-progress-bar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import InputPoll from "@/components/common/polls/components/poll/InputPoll";
import SimplePoll from "@/components/common/polls/components/poll/SimplePoll";
import TestProgressBar from "@/components/common/polls/components/common/TestProgressBar";
import TestTimer from "@/components/common/polls/components/common/TestTimer";
import PollControlButton from "@/components/common/polls/components/common/PollControlButton";
import { games, polls } from "@/store/modules/store.namespaces";
import { test } from "@/constants/polls/polls-module-types";
import { SET_LAST_GAME_QUESTION_TIME } from "@/store/modules/games/mutation-types";
import {
  GAME_OVER_BY_TIME,
  SEND_MOUNTAIN_KING_ANSWER,
  SKIP_GAME_QUESTION,
} from "@/store/modules/games/action-types";

export default {
  components: {
    closed: SimplePoll,
    open: InputPoll,
    PollControlButton,
    TestProgressBar,
    TestTimer,
  },
  props: ["gameAlias", "userScoreMessage"],
  data() {
    return {
      gameTime: null,
      currentGameTime: null,
    };
  },
  methods: {
    ...mapActions(games, {
      skipKingGameQuestion: SKIP_GAME_QUESTION,
      gameOverByTime: GAME_OVER_BY_TIME,
    }),
    ...mapMutations(games, {
      lastGameQuestionTime: SET_LAST_GAME_QUESTION_TIME,
    }),
    postQuizAnswer() {
      this.lastGameQuestionTime(this.currentGameTime);
      this.$refs.pollComponent.sendAnswer(SEND_MOUNTAIN_KING_ANSWER);
    },
    skipQuestion() {
      this.$refs.pollComponent.clearAnswers();
      this.skipKingGameQuestion();
    },
    timeTicker(e) {
      this.currentGameTime = e;
    },
    timeIsOut(e) {
      this.gameOverByTime();
    },
  },
  computed: {
    ...mapState(`${polls}/${test}`, ["activePoll"]),
    ...mapState(games, ["currentActiveGame"]),
    timeForGame() {
      return this.currentActiveGame.gameTime;
    },
    pollChain() {
      return this.currentActiveGame.pollsList;
    },
    activePollType() {
      return this.currentActiveGame.type;
    },
    questionsCount() {
      return this.currentActiveGame.pollsList.length;
    },
    currentQuestion() {
      return this.currentActiveGame.answeredQuestions.length + 1;
    },
    showPostScreen() {
      return this.currentActiveGame.postGameScreenVisible;
    },
    skipBtnAvailable() {
      return (
        this.currentActiveGame.pollsList.length -
          this.currentActiveGame.answeredQuestions.length >
        1
      );
    },
  },
};
</script>

<style scoped lang="less">
.quiz-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  .quiz {
    max-width: 100%;
  }
  .control-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    .skip {
      margin-right: 15px;
    }
  }
  .test-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .question-block {
    position: relative;
  }

  .timer-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .timer {
    width: 100px;
    margin: 0 auto;
  }

  .timer-fade-enter-active,
  .timer-fade-leave-active {
    transition: opacity 0.6s;
  }
  .timer-fade-enter,
  .timer-fade-leave-to {
    opacity: 0;
  }
}
</style>
