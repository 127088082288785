<template>
  <div v-if="correctBar" class="test-progress-bar">
    <div class="bar" :style="{ width: barWidth + '%' }">
      <div class="bar-marker" :class="{ 'to-bottom': legendToBottom }">
        <span class="marker-string" :class="{ beginning, ending }">
          {{
          barInfoString
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestProgressBar",
  props: ["questionsCount", "currentQuestionNumber", "legendToBottom"],
  computed: {
    correctBar() {
      return (
        this.questionsCount &&
        Number.isInteger(this.questionsCount) &&
        this.questionsCount > 0 &&
        Number.isInteger(this.currentQuestionNumber) &&
        this.questionsCount >= this.currentQuestionNumber
      );
    },
    barWidth() {
      let segment = 100 / this.questionsCount;
      return segment * this.currentQuestionNumber;
    },
    barInfoString() {
      return `${this.currentQuestionNumber} ${this.$t("common.of")} ${
        this.questionsCount
      }`;
    },
    beginning() {
      return +this.currentQuestionNumber === 0;
    },
    ending() {
      return +this.currentQuestionNumber === +this.questionsCount;
    },
  },
};
</script>

<style scoped lang="less">
.test-progress-bar {
  width: 100%;
  height: 15px;
  background-color: var(--base-border-color);
  position: relative;
  .bar {
    position: absolute;
    height: 15px;
    left: 0;
    background-color: var(--mute-text-color);
    transition: width 0.5s;
    .bar-marker {
      position: absolute;
      top: -16px;
      border-right: 1px solid var(--mute-text-color);
      height: 15px;
      width: 100%;
      transition: 0.5s;
      .marker-string {
        color: var(--mute-text-color);
        position: absolute;
        top: -16px;
        width: 50px;
        right: -28px;
        font-size: 12px;
        text-align: center;
        &.beginning {
          right: -50px;
          text-align: left;
          padding-left: 2px;
        }
        &.ending {
          right: 0;
          text-align: right;
          padding-right: 2px;
        }
      }
      &.to-bottom {
        top: 10px;
        .marker-string {
          top: 14px;
        }
      }
    }
  }
}
</style>
