<template>
  <div class="poll-wrapper">
    <test-progress-bar
      v-if="pollChain"
      :questions-count="questionsCount"
      :currentQuestionNumber="pollChain.step + 1"
      legendToBottom="true"
    />
    <component
      :is="pollComponent"
      v-if="!sendingStatus"
      :key="key"
      ref="pollComponent"
      class="poll"
      :poll="poll"
      :pollChain="pollChain"
      :moduleType="moduleType"
      :error="showError"
      @clearError="clearErrorMessage"
    >
      <div v-if="timerTime > 0" slot="timer" class="timer-wrapper">
        <transition name="timer-fade">
          <test-timer
            class="timer"
            :start-time-stamp="timestampStart"
            :timer-period="timerPeriod"
            :initial-time-in-seconds="timerTime"
            :common-timer="true"
            @timer-tick="timeTicker"
            @time-is-out="timeIsOut"
          ></test-timer>
        </transition>
      </div>
    </component>
    <br />
    <div v-if="!sendingStatus && pollComponent" class="poll-wrapper__control-buttons">
      <poll-control-button
        v-if="skipBtnAvailable"
        :disabled="sendNewAnswerStatus"
        class="skip-button"
        @click="skipButtonClickHandler"
      >
        {{ $t("Tests.postponeQuestion") }}
      </poll-control-button>
      <poll-control-button :disabled="sendNewAnswerStatus" @click="postQuizAnswer">
        {{ $t("testNGames.answer") }}
      </poll-control-button>
    </div>
    <loader v-if="sendingStatus" class="poll-wrapper__loader" />
    <div class="poll-wrapper__error">{{ errorMessage }}</div>
    <smart-modal :visible="timeoutModalVisible" type="form">
      <template #full>
        <div :class="$style.header">
          <h2>{{ $t("Tests.timeExpired") }}</h2>
        </div>
        <div :class="$style.body">
          <span>{{ $t("Tests.pressToShowResults") }}</span>
        </div>
        <div :class="$style.footer">
          <ws-button color="primary" block lg @click="showResults">
            {{ $t("Tests.showResults") }}
          </ws-button>
        </div>
      </template>
    </smart-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import InputPoll from "@/components/common/polls/components/poll/InputPoll";
import SimplePoll from "@/components/common/polls/components/poll/SimplePoll";
import ChooseWrong from "@/components/common/polls/components/poll/ChooseWrongPoll";
import RelationPoll from "@/components/common/polls/components/poll/RelationPoll";
import RelationImagesPoll from "@/components/common/polls/components/poll/RelationImagesPoll";
import RatingPoll from "@/components/common/polls/components/poll/RatingPoll";
import WordCloud from "@/components/common/polls/components/poll/WordCloudPoll";
import Loader from "@/components/common/elements/Loader";
import * as pollTypes from "@/constants/polls/poll-types";
import { polls } from "@/store/modules/store.namespaces";
import WsButton from "@/components/base/WsButton";
import {
  SET_POLLCHAIN_DATA_TIMER,
  SET_SENDING_STATUS,
  SET_SHOW_RESULT_AS_COMMON,
  SKIP_TEST_POLL,
} from "@/store/modules/polls/mutation-types";
import {
  GET_TEST_RESULTS,
  // SEND_POLL_TEST_ANSWER,
  SET_TEST_TIMER,
  SHOW_POLL_TEST_RESULT,
  FINISH_TEST,
  GET_NEXT_QUESTION,
} from "@/store/modules/polls/action-types";
import TestTimer from "./common/TestTimer";
import TestProgressBar from "./common/TestProgressBar";
import PollControlButton from "./common/PollControlButton";
import SmartModal from "@/components/common/modals/SmartModal";
import AnalogScale from "./poll/AnalogScale";
import GradientScale from "./poll/GradientScale";

const pollComponentsMap = {
  [pollTypes.closed]: "SimplePoll",
  [pollTypes.several]: "SimplePoll",
  [pollTypes.open]: "InputPoll",
  [pollTypes.relation]: "RelationPoll",
  [pollTypes.relationImage]: "RelationImagesPoll",
  [pollTypes.chooseWrong]: "ChooseWrong",
  [pollTypes.rating]: "RatingPoll",
  [pollTypes.wordCloud]: "WordCloud",
  [pollTypes.gradientScale]: "GradientScale",
  [pollTypes.analogScale]: "AnalogScale",
};

export default {
  name: "PollTest",
  components: {
    SimplePoll,
    InputPoll,
    RelationPoll,
    RelationImagesPoll,
    ChooseWrong,
    RatingPoll,
    WordCloud,
    PollControlButton,
    TestProgressBar,
    Loader,
    TestTimer,
    SmartModal,
    WsButton,
    AnalogScale,
    GradientScale,
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    pollChain: {
      type: Object,
      required: true,
    },
    sendingStatus: {
      type: Boolean,
      default: false,
    },
    moduleType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: "",
      timeForTest: 0,
      timeoutModalVisible: false,
      // Ключ для обновления компонента
      key: 0,
    };
  },
  computed: {
    ...mapState(polls, ["sendNewAnswerStatus"]),
    skipBtnAvailable() {
      return (
        this.pollChain &&
        this.pollChain.polls &&
        this.pollChain.step + 1 < this.pollChain.questionsNum
      );
    },
    timerTime() {
      return this.timeForTest || this.pollChain.timerOut;
    },
    timestampStart() {
      return +this.pollChain.time_started;
    },
    timerPeriod() {
      return +this.pollChain.timer;
    },
    pollComponent() {
      return pollComponentsMap[this.poll.type] || "";
    },
    actionPayload() {
      return {
        type: this.moduleType,
        payload: this.pollChain.id,
      };
    },
    questionsCount() {
      return this.pollChain.polls && this.pollChain.questionsNum;
    },
  },
  watch: {
    pollChain: {
      handler(value) {
        if (value.isRepeated && value.timerDelta) {
          this.$notify({
            group: "broadcast",
            type: "error",
            duration: -1,
            max: 1,
            title: this.$t("Tests.repeatedTest"),
            text: this.$t("Tests.repeatedTestDescription"),
            ignoreDuplicates: true,
          });
        }
      },
      deep: true,
    },
    // Отслеживание изменения вопросов.
    // Необходимо обновлять компонент при изменении вопроса.
    // Так, как могут возникнуть проблемы, при которых элемент оставит данные прошлого вопроса.
    poll: {
      handler() {
        this.key += 1;
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.setShowResultAsCommon({
      type: this.moduleType,
      payload: false,
    });
  },
  mounted() {
    this.setPollChainDataTimer(this.moduleType);
  },
  methods: {
    ...mapMutations(polls, {
      skipTestPoll: SKIP_TEST_POLL,
      setPollChainDataTimer: SET_POLLCHAIN_DATA_TIMER,
      setSendingStatus: SET_SENDING_STATUS,
      setShowResultAsCommon: SET_SHOW_RESULT_AS_COMMON,
    }),
    ...mapActions(polls, {
      // setTestTimer: SET_TEST_TIMER,
      getTestResults: GET_TEST_RESULTS,
      showTestPollResult: SHOW_POLL_TEST_RESULT,
      getNextQuestion: GET_NEXT_QUESTION,
      finishTest: FINISH_TEST,
    }),
    /**
     * Функция завершения текущего теста.
     */
    finishAndOpenModal() {
      this.finishTest({ testId: this.$route.params?.testId, type: this.moduleType });
      this.timeoutModalVisible = true;
    },
    /**
     * Показывает результаты теста.
     */
    showResults() {
      this.getTestResults({ payload: this.$route.params?.testId, type: this.moduleType }).then(
        () => {
          this.showTestPollResult({ testId: this.$route.params?.testId, type: this.moduleType });
        },
      );
    },
    clearErrorMessage() {
      this.errorMessage = "";
    },
    timeTicker(time) {
      this.timeForTest = time;
      if (time === 1) {
        setTimeout(() => {
          // this.setTestTimer({
          //   id: this.pollChain.id,
          //   finish: true,
          // });
          this.finishAndOpenModal();
        }, 500);
      }
    },
    /**
     * Функция остановки таймера
     */
    async timeIsOut() {
      // this.setTestTimer({
      //   id: this.pollChain.id,
      //   finish: true,
      // });
      this.finishAndOpenModal();
    },
    showError(error) {
      this.errorMessage = error;
    },
    postQuizAnswer() {
      this.setPollChainDataTimer(this.moduleType);
      // this.$refs.pollComponent.sendAnswer(SEND_POLL_TEST_ANSWER);
      this.$refs.pollComponent.sendAnswer();
    },
    skipButtonClickHandler() {
      this.setPollChainDataTimer(this.moduleType);
      // this.$refs.pollComponent.sendAnswer(SEND_POLL_TEST_ANSWER);
      this.$refs.pollComponent.skipQuestion();
    },
  },
};
</script>

<style scoped lang="less">
.poll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  &__control-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 9px;

    .skip-button {
      margin-right: 15px;
    }
  }

  &__loader {
    margin: 25% auto;
  }
}

.poll {
  margin-top: 45px;
  width: 100%;
  max-width: 70%;

  @media (max-width: 1280px) {
    margin-top: 20px;
    max-width: 90%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ::v-deep .poll-slider {
    margin: 0 auto 60px;
  }
}

.timer-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.timer {
  width: 100px;
  margin: 0 auto;
}

.timer-fade-enter-active,
.timer-fade-leave-active {
  transition: opacity 0.3s;
}

.timer-fade-enter,
.timer-fade-leave-to {
  opacity: 0;
}

.is-repeat {
  margin-top: 5px;
  background-color: rgba(247, 152, 67, 0.5);
  padding: 20px;
  border-radius: 5px;
  color: white;
  border: 1px solid var(--base-color);
  position: absolute;
  right: 20px;
  bottom: 20px;
}

@media (max-width: 550px) {
  .poll {
    width: 100%;
    padding: 0 15px;
  }
}
</style>

<style lang="scss" module>
.header {
  h2 {
    text-align: center;
    font-size: 24px;
  }
}

.body {
  flex: 1 1 auto;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  @media (min-width: 900px) {
    width: 345px;
  }
}

.footer {
  flex: 0 0 auto;
}
</style>
