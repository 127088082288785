<template>
  <div class="pin">
    {{ $t("common.visitWebsite") }}
    <b>whenspeak.ru</b>
    {{ $t("common.enterTheNumber") }}
    <b class="pin__number">{{ roomNumber }}</b>
  </div>
</template>

<script>
export default {
  name: "PinMessage",
  props: {
    roomNumber: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.pin {
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  &__number {
    vertical-align: middle;
    font-size: 36px;
    line-height: 52px;

    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
}
</style>
