<template>
  <room-style-provider #default="{ primaryButton }">
    <div class="create-chat">
      <header-chat>
        <div class="header-chat__button" @click="goHome">
          <ws-icon light xl color="primary">chevron-left</ws-icon>
          <div class="header-chat__button-text">{{ $t("common.back") }}</div>
        </div>
        <div class="info-chat">{{ $t("Chat.newChat") }}</div>
      </header-chat>
      <container-scroll>
        <layout-indent-sides>
          <div>
            <ws-button class="container-ws-button" lg color="primary" @click="createGroupChat">
              <ws-icon light>plus</ws-icon>
              {{ $t("Chat.createGroupChat") }}
            </ws-button>
          </div>

          <div v-if="!privateChatsDisabled" class="search-block">
            <div class="ws-input__label">
              {{ $t("Chat.writeToParticipant") }}
            </div>
            <ws-input
              :placeholder="$t('common.search')"
              prefix-icon="icon-magnifier"
              @input="searchUser"
            ></ws-input>
          </div>
        </layout-indent-sides>
        <ws-list-users
          v-if="!privateChatsDisabled"
          :user-name="name"
          @select-user="startChatWithUser"
        ></ws-list-users>
      </container-scroll>
    </div>
  </room-style-provider>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import { room, chats } from "@/store/modules/store.namespaces";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { CHATS_SET_PROPS } from "@/store/modules/chats/mutation-types";

import RoomStyleProvider from "@/components/common/roomStyle/RoomStyleProvider";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import { START_PRIVATE_CHANNEL } from "../GraphQl/mutation/mutation";

import HeaderChat from "../components/Header";
import WsInput from "../components/Input";
import ContainerScroll from "../components/ContainerScroll";
import LayoutIndentSides from "../components/LayoutIndentSides";
import WsListUsers from "../components/List";

export default {
  name: "CreateChat",
  components: {
    HeaderChat,
    WsInput,
    WsButton,
    LayoutIndentSides,
    WsListUsers,
    ContainerScroll,
    RoomStyleProvider,
    WsIcon,
  },
  data() {
    return {
      users: [],
      titleChat: "",
      participants: 0,
      first: 9,
      after: 0,
      oldScroll: "",
      loading: false,
      loadMore: true,
      name: "",
    };
  },
  computed: {
    ...mapState(room, {
      entityId: state => state.roomInfo?.external_chat_entity_id,
    }),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    privateChatsDisabled() {
      return this.availableRoomModules.disablePrivateChat;
    },
  },
  created() {
    this.updateChatsProps({
      channelId: null,
    });
  },
  methods: {
    ...mapMutations(chats, {
      updateChatsProps: CHATS_SET_PROPS,
    }),
    startChatWithUser({ user }) {
      this.$apollo
        .mutate({
          mutation: START_PRIVATE_CHANNEL,
          variables: {
            user_id: user.id,
            entity_id: this.entityId,
          },
        })
        .then(data => {
          this.updateChatsProps({
            channelId: data.data.startPrivate.id,
            lastMsgId: 0,
            countMsgs: 10,
            userId: user.id,
            avatar: user.node.avatar,
          });
          this.$emit("change-view", "Messages");
        });
    },
    searchUser(value) {
      this.name = value;
      this.after = 0;
      this.loadMore = true;
    },
    createGroupChat() {
      this.$emit("change-view", "CreateGroupChat");
    },
    goBack() {
      this.$emit("go-back-view", -1);
    },
    goHome() {
      this.$emit("go-home-view");
    },
  },
};
</script>

<style scoped lang="less">
@black: #000000;

@gray: #dcdcdc;

.create-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header-chat__inner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.ws-input__label {
  margin-bottom: 10px;
  color: @black;
}

.header-chat__button {
  display: flex;
  align-items: center;
  width: 100px;
  padding-left: 5px;
  cursor: pointer;

  &-text {
    margin-left: 5px;
  }
}

.container-ws-button {
  margin-top: 10px;
}

.search-block {
  margin-top: 20px;
}
</style>
