<template>
  <div class="create-group-chat">
    <header-chat>
      <div class="header-chat__button" @click="goBack">
        <ws-icon color="primary" light xl>chevron-left</ws-icon>
        <div class="header-chat__button-text">{{ $t("common.back") }}</div>
      </div>

      <div class="info-chat">
        <ws-avatar :title="titleChat ? titleChat : $t('Chat.newGroupChat')"></ws-avatar>
        <div class="info-chat__title">
          {{ titleChat ? titleChat : $t("Chat.newGroupChat") }}
        </div>
      </div>
    </header-chat>
    <container-scroll>
      <div class="setting-chat">
        <div class="setting-chat__item">
          <div class="title-item-setting">{{ $t("Chat.groupChatName") }}</div>
          <ws-input
            :placeholder="$t('Chat.enterChatName')"
            :invalid="!!errorMessage"
            @input="setTitleChat($event)"
          ></ws-input>
          <p v-if="errorMessage" class="setting-chat__error-message">{{ errorMessage }}</p>
        </div>
        <div class="setting-chat__item">
          <div class="container-title-setting">
            <div class="title-item-setting">
              {{ $t("Chat.selectParticipants") }}
            </div>
            <div class="count-participants">{{ participantsCountText }}</div>
          </div>
          <ws-input
            :placeholder="$t('common.search')"
            prefix-icon="icon-magnifier"
            @input="searchUser($event)"
          ></ws-input>
        </div>
      </div>
      <ws-list-users
        :user-name="userName"
        :is-dot="true"
        active-icon="fal fa-check"
        icon="fal fa-plus"
        @select-user="selectUser"
        @users="users = $event"
      ></ws-list-users>
    </container-scroll>
    <footer-chat>
      <ws-button lg color="primary" :disabled="Boolean(!titleChat)" @click="createChat">
        {{ $t("Chat.createChat") }}
      </ws-button>
    </footer-chat>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { room, chats } from "@/store/modules/store.namespaces";
import { CHATS_SET_PROPS } from "@/store/modules/chats/mutation-types";
import { channelTypes, TOO_LONG_ERROR_TYPE } from "@/constants/chats/chats-consts";
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";
import { CHAT_CREATE_CHANNEL } from "../GraphQl/mutation/mutation";
import HeaderChat from "../components/Header";
import WsInput from "../components/Input";
import ContainerScroll from "../components/ContainerScroll";
import FooterChat from "../components/Footer";
import WsListUsers from "../components/List";
import WsAvatar from "../components/Avatar";

export default {
  name: "CreateGroupChat",
  components: {
    HeaderChat,
    WsInput,
    WsButton,
    FooterChat,
    WsListUsers,
    WsIcon,
    WsAvatar,
    ContainerScroll,
  },
  data() {
    return {
      chatFindChannel: [],
      titleChat: "",
      participants: 0,
      usersAdded: new Set(),
      users: [],
      first: 15,
      after: 0,
      oldScroll: "",
      loading: false,
      loadMore: true,
      userName: "",
      errorMessage: "",
    };
  },
  computed: {
    ...mapState(room, {
      roomNumber: state => state.room,
      entityId: state => state.roomInfo?.external_chat_entity_id,
    }),
    participantsCountText() {
      return this.$t("Chat.participantsWithCount", {
        participants: this.participants,
      });
    },
  },
  methods: {
    ...mapMutations(chats, {
      updateChatsProps: CHATS_SET_PROPS,
    }),
    goBack() {
      this.$emit("go-back-view", -1);
      this.users = this.users.map(user => {
        user.select = false;
        return user;
      });
      this.usersAdded.clear();
      this.participants = 0;
    },
    async createChat() {
      try {
        this.errorMessage = "";
        const selectedUsers = Array.from(this.usersAdded);
        const data = await this.$apollo.mutate({
          mutation: CHAT_CREATE_CHANNEL,
          variables: {
            name: this.titleChat,
            type: channelTypes.group,
            user_ids: selectedUsers,
            entity_id: this.entityId,
          },
        });
        this.updateChatsProps({
          channelId: data.data.chatCreateChannel.id,
          lastMsgId: 0,
          countMsgs: 0,
        });
        this.$emit("change-view", "Messages");
        this.users = this.users.map(user => {
          user.select = false;
          return user;
        });
        this.usersAdded.clear();
        this.participants = 0;
      } catch (e) {
        if (e.message?.includes(TOO_LONG_ERROR_TYPE)) {
          this.errorMessage = this.$t("Chat.nameTooLong");
        }
      }
    },

    searchUser(value) {
      this.userName = value;
      this.after = 0;
      this.loadMore = true;
    },
    setTitleChat(event) {
      this.titleChat = event;
    },
    /**
     * Выбрать пользователя для добавления в чат
     *
     * @param {object} event - Событие
     * @param {object} event.user - Выбранный пользователь
     */
    selectUser({ user }) {
      this.participants += user.select ? 1 : -1;
      this.usersAdded.has(user.id) ? this.usersAdded.delete(user.id) : this.usersAdded.add(user.id);
    },
  },
};
</script>

<style scoped lang="less">
@green: #13c809;

.create-group-chat {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow: hidden;
}

.setting-chat {
  padding: 0 10px 15px;
}

.header-chat__button {
  display: flex;
  align-items: center;
  width: 100px;
  padding-left: 5px;
  cursor: pointer;

  &-text {
    margin-left: 5px;
  }
}

.info-chat {
  display: flex;
  align-items: center;
  max-width: 230px;
  overflow-x: hidden;
  cursor: pointer;

  &__title {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.setting-chat__item {
  margin-top: 15px;
}

.container-title-setting {
  display: flex;
}

.count-participants {
  color: #777777;
}

.title-item-setting {
  flex: 1;
  margin-bottom: 10px;
  color: #000;
}

.item-setting {
  margin-top: 15px;
}

.container-ws-icon {
  .ws-icon__active {
    color: @green;
  }
}

.setting-chat__error-message {
  padding-left: 10px;
  color: #f05735;
}
</style>
