<template>
  <div class="screen-nav">
    <a class="screen-nav__link" @click.prevent="setPinStatus">{{
      $t("navigation.pin")
    }}</a>
    <router-link
      v-for="item in navItems"
      :key="item.name"
      :to="item.to"
      class="screen-nav__link"
      data-testname="nav-link"
      @click.native="toggleUserPopup"
    >
      {{ item.text }}
    </router-link>
    <polls-select-container
      v-if="pollsSelectAvailable"
      class="screen-nav__polls-select"
    />
    <replace-room
      v-if="replaceRoomAvailable"
      :title="$t('Speaker.chooseRoom')"
      @change-room="toggleUserPopup"
    />
  </div>
</template>

<script>
import PollsSelectContainer from "@/components/common/polls/PollsSelectContainer";
import ReplaceRoom from "@/components/common/user/ReplaceRoom";

export default {
  name: "ScreenNavigation",
  components: { PollsSelectContainer, ReplaceRoom },
  props: {
    navItems: {
      type: Array,
      default: () => [],
    },
    replaceRoomAvailable: {
      type: Boolean,
      default: false,
    },
    pollsSelectAvailable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setPinStatus() {
      this.$emit("pin-status");
    },
    toggleUserPopup() {
      this.$emit("toggle-sidebar");
    },
  },
};
</script>

<style scoped lang="less">
.screen-nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__link {
    margin: 1em 0;
    color: black;
    cursor: pointer;
  }

  &__polls-select {
    width: 100%;
    text-align: center;
  }
}
</style>
