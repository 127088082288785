<template>
  <div :class="$style.container">
    {{ $t("Tests.showResults") }}:
    <polls-select
      :pollsAndTests="unityArray"
      @selectedPoll="selectedPoll"
      @closeScreen="closeScreen"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import pollsSelect from "@/components/common/polls/PollsSelect";
import { GET_POLLS, GET_SPEAKER_TEST } from "@/store/modules/speaker/modules/polls/action-types";
import { speakerPolls, presentation, room, polls } from "@/store/modules/store.namespaces";
import { TOGGLE_USER_POPUP_VISIBLE } from "@/store/modules/common/mutation-types";
import { SHOW_POLL_SCORE, CLOSE_POLL_SCORE } from "@/store/modules/polls/action-types";
import { interactive } from "@/constants/polls/polls-module-types";
import { SET_CHEAT_POINTS } from "@/store/modules/polls/mutation-types";

export default {
  components: {
    pollsSelect,
  },
  data() {
    return {
      default: [
        {
          id: "default",
          title: this.$t("PollRunning.nothingSelected"),
        },
      ],
    };
  },
  computed: {
    ...mapState(presentation, ["currentPresentationInfo"]),
    ...mapState(speakerPolls, ["isLoading", "polls", "tests"]),
    ...mapState(polls, ["isCheatPoints"]),

    currentPresentationInfoId() {
      return this.currentPresentationInfo.id;
    },
    unityArray() {
      const polls = this.polls.filter(poll => !!poll.count_answers);
      const tests = Object.values(this.tests)
        .flat()
        .filter(test => !!test.users_passed);
      return [...polls, ...tests, ...this.default];
    },
  },
  watch: {
    currentPresentationInfoId(newValue) {
      if (newValue) {
        this.getPollsAndTest();
      }
    },
  },
  created() {
    this.getPollsAndTest();
  },
  methods: {
    ...mapActions(speakerPolls, {
      getPolls: GET_POLLS,
      getSpeakerTest: GET_SPEAKER_TEST,
    }),
    ...mapActions(polls, {
      showPollScore: SHOW_POLL_SCORE,
      closePollScore: CLOSE_POLL_SCORE,
    }),
    ...mapMutations(room, {
      toggleUserPopupVisible: TOGGLE_USER_POPUP_VISIBLE,
    }),
    ...mapMutations(polls, {
      setCheatPoints: SET_CHEAT_POINTS,
    }),
    getPollsAndTest() {
      this.getPolls({
        pres: this.currentPresentationInfoId,
      });
      this.getSpeakerTest(this.currentPresentationInfoId);
    },
    closeScreen(val) {
      const request = {
        type: interactive,
        payload: val,
        isScreen: true,
      };
      this.closePollScore(request);
      this.toggleUserPopupVisible();
      this.setCheatPoints(false);
    },
    async selectedPoll(val) {
      const request = {
        type: interactive,
        payload: val,
        isScreen: true,
      };
      await this.showPollScore(request);
      this.toggleUserPopupVisible();
      this.setCheatPoints(true);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  margin-top: 20px;
}
</style>