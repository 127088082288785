<script>
import BaseWebSocketContainer from "@/components/common/web-socket/BaseWebSocketContainer";
import { socket, socketScreen } from "@/store/modules/store.namespaces";
import socketScreenModule from "@/store/modules/socket/modules/screen/screen";

export default {
  name: "ScreenWebSocketContainer",
  extends: BaseWebSocketContainer,
  computed: {
    storeModuleNamespace() {
      return socketScreen;
    },
  },
  methods: {
    chooseNamespace(type) {
      if (socketScreenModule.actions[type]) {
        return this.storeModuleNamespace;
      }
      return socket;
    },
  },
};
</script>
