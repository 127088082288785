<template>
  <stf-select :value="value.title" @input="selectedPoll">
    <div slot="label">{{ $t("Tests.notSelect") }}</div>
    <div slot="value" :title="value.title">
      <div v-if="value">
        <span v-line-clamp="2">{{ value.title }}</span>
      </div>
    </div>
    <section class="options">
      <stf-select-option
        v-for="poll of pollsAndTests"
        :key="poll.id"
        :class="{ 'stf-select-option_selected': poll.id === value.id }"
        :value="poll"
        :title="poll.title"
      >
        <span v-line-clamp="2"> {{ poll.title }} </span>
      </stf-select-option>
    </section>
  </stf-select>
</template>

<script>
import { StfSelect, StfSelectOption } from "stf-vue-select";

export default {
  components: {
    StfSelect,
    StfSelectOption,
  },
  props: {
    pollsAndTests: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.pollsAndTests[this.pollsAndTests.length - 1];
  },
  methods: {
    selectedPoll(val) {
      this.value = val;
      if (val.id === "default") {
        this.$emit("closeScreen", val);
      } else {
        this.$emit("selectedPoll", val);
      }
    },
  },
};
</script>
